import Context from "@/services/Context.js";
import Vue from "vue";

const langName = "langName";
const langFile = "langFile";
const muteMode = "muteMode";
const silentMode = "silentMode";
const silentFromTime = "silentFromTime";
const silentToTime = "silentToTime";
const persistentMode = "persistentMode";
const volumeLevel = "volumeLevel";
const vibrateMode = "vibrateMode";
const confirmedMode = "confirmedMode";
const historyDays = "historyDays";
const debugMode = "debugMode";
const appVersion = "appVersion";
const id = "id";
const seed = "seed";
const token = "token";
const alternativeBackend = "alternativeBackend";

const logs = [];
let pollDummyValue = true;
let window = null;

function addObservableProperty(name, def) {
  let propChanged = Vue.observable({ propValue: {} });
  Object.defineProperty(Vue.prototype, "$" + name, {
    get() {
      return propChanged.propValue;
    },
    set(value) {
      propChanged.propValue = value;
    },
  });
  if (def !== undefined) {
    setObservableProperty(name, def);
  }
}

function setObservableProperty(name, expression) {
  Vue.set(Vue.prototype, "$" + name, expression);
}

function setItem(key, value) {
  if (key !== null) {
    updateObservableProperty(key, value);
    updateAppStore(key, value);
    localStorage.setItem(key, JSON.stringify(value)); // JSON preserves types and makes it possible to store objects
  }
}

function getItem(key, def) {
  let value = localStorage.getItem(key);
  if (!value) {
    value = def;
    console.warn(Context.tag("getItem", "no stored value for key=" + key + " using def=" + JSON.stringify(value)));
    if (value !== null) {
      setItem(key, value);
    }
  } else {
    value = JSON.parse(value); // JSON preserves types and makes it possible to store objects
  }
  return value;
}

function updateObservableProperty(key, value) {
  switch (key) {
    case langName:
      setObservableProperty("languageChanged", value);
      console.log(Context.tag("set", "languageChanged language=" + value));
      break;
    case debugMode:
      setObservableProperty("debugChanged", value);
      console.log(Context.tag("set", "debugChanged value=" + value));
      break;
  }
}

function updateAppStore(key, value) {
  if (Context.getEnvironment() === Context.environment_android) {
    switch (key) {
      case id:
        window.androidNotifierInterface.setId(value);
        break;
      case persistentMode:
        window.androidNotifierInterface.setPersistentMode(value);
        break;
      case volumeLevel:
        window.androidNotifierInterface.setVolumeLevel(value);
        break;
      case vibrateMode:
        window.androidNotifierInterface.setVibrateMode(value);
        break;
    }
  } else if (Context.getEnvironment() === Context.environment_ios) {
    let val = String(value);
    switch (key) {
      case volumeLevel:
        window.webkit.messageHandlers.setVolumeLevel.postMessage({ value: val });
        break;
      case vibrateMode:
        window.webkit.messageHandlers.setVibrateMode.postMessage({ value: val });
        break;
    }
  }
}

export default {
  langName,
  langFile,
  muteMode,
  silentMode,
  silentFromTime,
  silentToTime,
  persistentMode,
  volumeLevel,
  vibrateMode,
  confirmedMode,
  historyDays,
  debugMode,
  appVersion,
  id,
  seed,
  token,
  alternativeBackend,

  initialize(w) {
    if (w !== undefined) {
      window = w;
      addObservableProperty("filterChanged");
      addObservableProperty("languageChanged");
      addObservableProperty("errorChanged", false);
      addObservableProperty("logChanged");
      addObservableProperty("pollChanged");
      addObservableProperty("debugChanged");
    }
    this.get(langName, "English");
    this.get(langFile, "MessagesVMS.xml");
    this.get(muteMode, false);
    this.get(silentMode, false);
    this.get(silentFromTime, "00:00");
    this.get(silentToTime, "00:00");
    this.get(persistentMode, false);
    this.get(volumeLevel, 100);
    this.get(vibrateMode, true);
    this.get(confirmedMode, true);
    this.get(historyDays, 5);
    this.get(debugMode, false);
    this.get(alternativeBackend, false);
    this.get(appVersion, "");
    this.get(id, "-1");
    this.get(seed, "-1");
    this.get(token, "-1");
  },

  get(key, def) {
    if (def !== undefined) {
      return getItem(key, def);
    } else {
      return getItem(key, null);
    }
  },

  set(key, value) {
    setItem(key, value);
  },

  list() {
    let all = "";
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = localStorage[key];
      all = all + "[" + key + "=" + value + "] ";
    }
    return all;
  },

  clearAll() {
    console.log(Context.tag("clearAll", "clear all in store, also Android store"));
    localStorage.clear();
    this.initialize();
  },

  applyFilter(device) {
    console.log(Context.tag("applyFilter", "filterChanged=" + device));
    setObservableProperty("filterChanged", device);
  },

  clearFilter() {
    console.log(Context.tag("clearFilter", "filterChanged=null"));
    setObservableProperty("filterChanged", null);
  },

  raiseError() {
    setObservableProperty("errorChanged", true);
    //console.log(Context.tag("raiseError", "errorChanged value=true"));
  },

  clearError() {
    setObservableProperty("errorChanged", false);
    //console.log(Context.tag("clearError", "errorChanged value=false"));
  },

  logApp(msg) {
    logs.unshift(msg);
    //console.log(Context.tag("logApp", "msg=" + msg));
    if (logs.length > 20) {
      logs.shift();
    }
    setObservableProperty("logChanged", logs);
  },

  pollApp() {
    setObservableProperty("pollChanged", (pollDummyValue = !pollDummyValue));
    //console.log(Context.tag("pollApp", "value=" + pollDummyValue));
  },
};
