<template>
  <v-progress-linear :indeterminate="showLoading" color="white darken-2"></v-progress-linear>
</template>

<script>
export default {
  data() {
    return {
      showLoading: false,
    };
  },

  watch: {
    $pollChanged() {
      this.showLoading = true;
      setTimeout(() => {
        this.showLoading = false;
      }, 1000);
    },
  },
};
</script>

