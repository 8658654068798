import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Context from "@/services/Context.js";
import Store from "@/services/Store.js";
import EventService from "@/services/EventService.js";
import TestService from "@/services/TestService.js";
import AuthorizeService from "@/services/AuthorizeService.js";
import I18n from "@/components/I18n.vue";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.prototype.$store = Store;
Vue.component("I18n", I18n);

new Vue({
  router,
  vuetify,
  beforeCreate() {
    Context.initialize(window);
  },
  created() {
    Store.initialize(window);
  },
  beforeMount() {
    EventService.initialize(window);
    TestService.initialize(window);
    AuthorizeService.initialize(window);
  },
  render: (h) => h(App),
}).$mount("#app");
