<template>
  <div>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title class="headline"><I18n i18n-key="ConfirmAlarm" i18n-def="Confirm?" /></v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div>
              <h3>{{ device }}</h3>
            </div>
            <div class="ml-2">
              <h3>{{ date }}</h3>
            </div>
          </div>
          <p class="mt-2">{{ message }}</p>
        </v-card-text>
        <v-card-actions class="pr-4">
          <v-btn text width="50%" elevation="2" @click="confirmAlarm(false)">
            <v-icon big center>mdi-close</v-icon>
          </v-btn>
          <v-btn text width="50%" elevation="2" @click="confirmAlarm(true)">
            <v-icon big center>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//
// Usage example:
// <EventConfirm ref="eventConfirm" :device="device" :date="date" :message="message" :event-id="id" />
//

import EventService from "@/services/EventService.js";

export default {
  props: {
    device: {
      type: String,
      default: "device",
    },
    date: {
      type: String,
      default: "date",
    },
    message: {
      type: String,
      default: "message",
    },
    eventId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    showDialog() {
      this.dialog = true;
    },

    confirmAlarm(sendConfirmation) {
      if (sendConfirmation) {
        console.log("confirmAlarm: yes");
        EventService.confirmAlarm(this.eventId);
      } else {
        console.log("confirmAlarm: no");
      }
      this.dialog = false;
    },
  },
};
</script>

