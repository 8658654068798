<template>
  <div>
    <v-menu transition="scale-transition" :close-on-click="closeOnClick">
      <template #activator="{ on, attrs }">
        <v-btn small class="ma-4" color="primary" v-bind="attrs" v-on="on" @click="getDevices">
          <v-icon small center>mdi-filter</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader>Filter</v-subheader>
        <div class="mx-2">
          <v-btn v-for="(device, index) in devices" :key="index" class="no-uppercase mb-4" elevation="2" block small @click="applyFilter(device)">{{ device }}</v-btn>
          <v-btn class="mb-4" elevation="2" block small @click="clearFilter()">
            <v-icon small center>mdi-filter-off</v-icon>
          </v-btn>
          <v-btn elevation="2" block small>
            <v-icon big center>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Store from "@/services/Store.js";
import EventService from "@/services/EventService.js";

export default {
  data() {
    return {
      devices: [],
      closeOnClick: true,
    };
  },

  methods: {
    getDevices() {
      this.devices = EventService.getDevices();
    },

    applyFilter(filter) {
      Store.applyFilter(filter);
    },

    clearFilter() {
      Store.clearFilter();
    },
  },
};
</script>

<style scoped>
.no-uppercase {
  text-transform: none;
}
</style>

