<template>
  <v-container class="ma-0 pa-0">
    <p class="ma-0 pa-0 text--disabled"><I18n :i18n-key="i18nKey" i18n-def="Can't load language right now ..." /></p>
    <v-select v-model="selected" :items="languages" item-value="value" item-text="key" class="ma-0 pa-0" hide-details return-object solo @change="setLanguage"></v-select>
  </v-container>
</template>

<script>
//
// <LangList />
//

import Store from "@/services/Store.js";
import LanguageService from "@/services/i18n/LanguageService.js";
import AppTranslationService from "@/services/i18n/AppTranslationService.js";
import EventTranslationService from "@/services/i18n/EventTranslationService.js";

export default {
  props: {
    name: {
      type: String,
      default: "LangList",
    },
    storeKeyName: {
      type: String,
      default: null,
    },
    storeKeyFile: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selected: [],
      languages: [],
      i18nKey: null,
    };
  },

  computed: {
    def() {
      return [{ key: "English", value: "MessagesVMS.xml" }];
    },
  },

  beforeMount() {
    this.i18nKey = Store.get(this.storeKeyName);
  },

  mounted() {
    this.languages = this.def;
    this.selected = Store.get("langList.selected", this.def[0]);
    LanguageService.getLanguages(this.getLanguagesRsp);
  },

  methods: {
    getLanguagesRsp(languages) {
      this.languages = languages;
    },

    setLanguage() {
      console.log("setLanguage: lang=" + this.selected.key);
      Store.set("langList.selected", this.selected);
      Store.set(this.storeKeyName, this.selected.key);
      Store.set(this.storeKeyFile, this.selected.value);
      EventTranslationService.clearCache();
      AppTranslationService.clearCache();
      this.i18nKey = this.selected.key;
    },
  },
};
</script>

<style scoped>
p {
  font-size: 14px;
}
</style>

