<template>
  <div>
    <EventList />
  </div>
</template>

<script>
import EventList from "@/components/events/EventList.vue";
import AuthorizeService from "@/services/AuthorizeService.js";

export default {
  components: {
    EventList,
  },

  mounted() {
    if (AuthorizeService.isRegistered() === false) {
      this.$router.push("/authorize");
    }
  },
};
</script>
