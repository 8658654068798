import Context from "@/services/Context.js";
import Store from "@/services/Store.js";
import Alarm from "@/services/Alarm.js";
import AuthorizeService from "@/services/AuthorizeService.js";

let window = null;
let poller = null;
let alarmsRspFnc = null;
let startUp = true;
let lastAlarm = 0; // Get all
let notifications = [];
const confirmations = [];
const devices = [];

function alarmsRsp() {
  if (alarmsRspFnc !== null) {
    let notificationsClone = JSON.parse(JSON.stringify(notifications));
    // Perhaps only keep unconfirmd alarms?
    if (Store.get(Store.confirmedMode) === false) {
      for (let i = notificationsClone.length - 1; i >= 0; i--) {
        if (notificationsClone[i].dialoge === "false") {
          notificationsClone.splice(i, 1);
        }
      }
    }
    // List all devices
    parseDevices();
    // Callback
    alarmsRspFnc(notificationsClone);
    // Continue polling
    tryPollAlarms();
  }
}

function handleAlarms() {
  if (AuthorizeService.isRegistered()) {
    console.log(Context.tagApp("handleAlarms", "polled"));
    Store.pollApp();
    Context.backend()
      .get("get_events.php" + Context.backendIdUrl() + "&lastAlarm=" + lastAlarm)
      .then(
        (result) => {
          if (result.data.length > 0) {
            console.log(Context.tag("handleAlarms", "lastAlarm=" + lastAlarm + " length=" + result.data.length + " data=" + JSON.stringify(result.data)));
            let id = result.data[result.data.length - 1].id;
            if (lastAlarm !== id) {
              parseAlarmsRsp(result.data);
            }
            lastAlarm = id;
          }
          Store.clearError();
        },
        (error) => {
          console.error(Context.tagApp("handleAlarms", "error=" + error));
          Store.raiseError();
        }
      );
  } else {
    console.error(Context.tagApp("handleAlarms", "need to register"));
  }
}

function parseAlarmsRsp(events) {
  console.log(Context.tagApp("parseAlarmsRsp", "parse new data"));
  confirmations.length = 0;
  let prevNotificationsLength = notifications.length;
  splitEvents(events);
  parseMultiple();
  parseConfirmed();
  parseColor();
  parseCow();
  parseOld();
  // Trig user alarm?
  //console.log(Context.tagApp("parseAlarmsRsp", "notifications.length=" + notifications.length + " prevNotificationsLength=" + prevNotificationsLength + " embeddedMode=" + embeddedMode));
  if (notifications.length > prevNotificationsLength && startUp == false) {
    Alarm.alertUser(notifications[0]);
  }
  startUp = false;
  // Show all alarms
  Store.clearFilter();
  // Callback
  alarmsRsp();
}

function splitEvents(events) {
  // Copy, format date, and reverse order
  for (let i = 0; i < events.length; i++) {
    let date = new Date(events[i].ev.replace(" ", "T") + "+00:00"); // T: Fix for iOS
    //console.error("DATE CHECK: events[" + i + "].ev=" + events[i].ev + " date=" + date);
    events[i].ev = date.toLocaleString(navigator.language, {
      timeStyle: "short",
      dateStyle: "short",
    });
    if (events[i].st === "2") {
      confirmations.unshift(events[i]);
    } else {
      notifications.unshift(events[i]);
    }
  }
  //console.log(Context.tag("parseAlarmsRsp", "confirmations parsed dates: data=" + JSON.stringify(confirmations)));
}

function parseMultiple() {
  // States: 0=unconfirmed, 1=confirmed, 2=recovery
  for (let i = 0; i < notifications.length; i++) {
    for (let j = i + 1; j < notifications.length; j++) {
      if (notifications[i].de === notifications[j].de) {
        notifications[j].st = "1"; // Event is confirmed, since there can only be one active alarm for each device
      }
    }
  }
  //console.log(Context.tag("parseMultiple", "notifications parsed states: data=" + JSON.stringify(notifications)));
}

function parseConfirmed() {
  // States: 0=unconfirmed, 1=confirmed, 2=recovery
  for (let i = 0; i < notifications.length; i++) {
    for (let j = 0; j < confirmations.length; j++) {
      if (Number(notifications[i].id) < Number(confirmations[j].id) && notifications[i].de === confirmations[j].de) {
        notifications[i].st = "1"; // Event is confirmed, by user
      }
    }
  }
  //console.log(Context.tag("parseConfirmed", "notifications parsed states: data=" + JSON.stringify(notifications)));
}

function parseColor() {
  for (let i = 0; i < notifications.length; i++) {
    let dialoge = "false"; // Default dont show confirm dialoge
    let color = "black"; // Default color black as an dev error
    if (notifications[i].st === "0") {
      if (notifications[i].se === "FatalError") {
        color = "red";
        dialoge = "true";
      } else if (notifications[i].se === "UserNotification") {
        color = "blue";
        dialoge = "true";
      }
    } else if (notifications[i].st === "1") {
      color = "white";
    }
    notifications[i].dialoge = dialoge;
    notifications[i].color = color;
  }
  //console.log(Context.tag("parseColor", "notifications parsed colors: data=" + JSON.stringify(notifications)));
}

function parseCow() {
  for (let i = 0; i < notifications.length; i++) {
    if (notifications[i].ma === "4" && notifications[i].mi === "118") {
      notifications[i].cow = notifications[i].u6;
    } else if (notifications[i].ma === "1" && notifications[i].mi === "110") {
      notifications[i].cow = notifications[i].u1;
    } else {
      notifications[i].cow = "";
    }
  }
  //console.log(Context.tag("parseCow", "notifications parsed cow id: data=" + JSON.stringify(notifications)));
}

function parseOld() {
  let days = Store.get(Store.historyDays);
  let expireDate = new Date().getTime() - days * 24 * 60 * 60 * 1000;
  for (let i = notifications.length - 1; i >= 0; i--) {
    let eventDate = new Date(notifications[i].ev.replace(" ", "T")).getTime(); // T: Fix for iOS
    if (eventDate < expireDate) {
      notifications.splice(i, 1);
      //console.warn(Context.tag("parseOld", "removed old event"));
    }
  }
}

function parseDevices() {
  devices.length = 0;
  for (let i = 0; i < notifications.length; i++) {
    let device = notifications[i].de;
    if (devices.indexOf(device) === -1) {
      devices.push(device);
    }
  }
  //console.log(Context.tag("parseDevices", "parsed devices: data=" + JSON.stringify(devices)));
}

function handleConfirmAlarm(alarm) {
  console.log(Context.tagApp("handleConfirmAlarm", "alarm=" + alarm));
  if (AuthorizeService.isRegistered()) {
    Context.backend()
      .get("confirm_event.php" + Context.backendIdUrl() + "&alarm=" + alarm)
      .then(
        (result) => {
          if (result.data.rc === "ok") {
            delayedPollAlarms();
          } else {
            console.error(Context.tagApp("handleConfirmAlarm", "error: rc=" + result.data.rc));
          }
        },
        (error) => {
          console.error(Context.tagApp("handleConfirmAlarm", "error=" + error));
        }
      );
  } else {
    console.error(Context.tagApp("handleConfirmAlarm", "need to register"));
  }
}

function delayedPollAlarms() {
  if (AuthorizeService.isRegistered()) {
    console.log(Context.tag("delayedPollAlarms", "called"));
    stopPollAlarms();
    setTimeout(tryPollAlarms, 1000 * 1);
  }
}

function tryPollAlarms() {
  if (AuthorizeService.isRegistered()) {
    if (poller === null) {
      console.log(Context.tag("pollAlarms", "enabled"));
      handleAlarms();
      poller = setInterval(handleAlarms, 1000 * 10);
    }
  } else {
    stopPollAlarms();
  }
}

function stopPollAlarms() {
  console.log(Context.tagApp("pollAlarms", "disabled"));
  clearInterval(poller);
  poller = null;
}

export default {
  initialize(w) {
    window = w;
    window.gotAlarm = this.gotAlarm;
    window.isActive = this.isActive;
    tryPollAlarms();
  },

  stopPoll() {
    stopPollAlarms();
  },

  getAlarms(rspFnc) {
    console.log(Context.tag("getAlarms", "called"));
    alarmsRspFnc = rspFnc;
    alarmsRsp();
  },

  confirmAlarm(alarm) {
    handleConfirmAlarm(alarm);
  },

  gotAlarm() {
    console.log(Context.tag("gotAlarm", "got new alarm"));
    handleAlarms();
  },

  getDevices() {
    return devices;
  },

  isActive(value) {
    let inForeground = value;
    console.log(Context.tag("isActive", "foreground=" + inForeground));
    if (inForeground === true || inForeground === "true") {
      tryPollAlarms();
    } else {
      stopPollAlarms();
    }
  },
};
