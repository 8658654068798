<template>
  <div>
    <v-btn class="ma-0 mb-4 mt-2" elevation="2" small block @click="useCamera">
      <I18n class="subtitle-5" i18n-key="StartQrCodeReader" i18n-def="Start qr-code reader" />
    </v-btn>
    <v-alert v-model="alert" dismissible :type="type">{{ message }}</v-alert>
    <div v-if="scanMode">
      <video v-show="false" ref="video" class="source" :width="videoWidth" :height="videoHeight" controls></video>
    </div>
    <div v-else>
      <input id="file-input" ref="fileuploader" class="d-none" type="file" accept="image/*" capture="environment" />
      <img v-show="false" id="file-output" :width="videoWidth" :height="videoHeight" />
    </div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
//
// https://github.com/cozmo/jsQR               This qr-code decoder is installed via "npm install jsqr"
// https://github.com/pulilab/vue-qr-reader    Vue code copied from this project, capture image support for iOS is added
//

import AuthorizeService from "@/services/AuthorizeService.js";
import jsQR from "jsqr";

export default {
  props: {
    scanMode: {
      type: Boolean,
      default: true,
    },
    useBackCamera: {
      type: Boolean,
      default: true,
    },
    stopOnScanned: {
      type: Boolean,
      default: true,
    },
    drawOnFound: {
      type: Boolean,
      default: true,
    },
    lineColor: {
      type: String,
      default: "#FF3B58",
    },
    lineWidth: {
      type: Number,
      default: 2,
    },
    videoWidth: {
      type: Number,
      default: 320,
    },
    videoHeight: {
      type: Number,
      default: 240,
    },
  },

  data() {
    return {
      active: false,
      message: undefined,
      alert: false,
      type: "error",
    };
  },

  watch: {
    active: {
      immediate: true,
      handler(active) {
        if (!active) {
          this.fullStop();
        }
      },
    },
  },

  beforeDestroy() {
    this.fullStop();
  },

  methods: {
    useCamera() {
      AuthorizeService.mayCallCameraPermissionDialogue();
      if (this.scanMode === true) {
        this.setupScanMode();
      } else {
        this.setupCaptureMode();
        this.$refs.fileuploader.click();
      }
    },

    showError(error) {
      if (error.name === "NotAllowedError") {
        this.message = "You need to grant camera access permisson.";
      } else if (error.name === "NotFoundError") {
        this.message = "No camera on this device.";
      } else if (error.name === "NotSupportedError") {
        this.message = "Secure context required (HTTPS, localhost)";
      } else if (error.name === "NotReadableError") {
        this.message = "Is the camera already in use?";
      } else if (error.name === "OverconstrainedError") {
        this.message = "Installed cameras are not suitable.";
      } else if (error.name === "StreamApiNotSupportedError") {
        this.message = "Stream API is not supported in this browser.";
      } else {
        this.message = "Unknown error: " + error;
      }
      this.type = "error";
      this.alert = true;
    },

    decoded(qr) {
      if (qr !== undefined && qr !== null && qr.startsWith("_") && qr.endsWith("_") && qr.length > 10) {
        console.log("decoded: qr=" + qr);
        AuthorizeService.register(this.registerRsp, qr);
        this.alert = false;
      } else {
        console.error("decoded: not valid qr=" + qr);
      }
    },

    native() {
      if (AuthorizeService.register(this.registerRsp) === true) {
        this.alert = false;
      } else {
        this.message = "Can not access native resources.";
        this.type = "error";
        this.alert = true;
      }
    },

    registerRsp(success) {
      if (success === true) {
        console.log("registerRsp: Your farm alarms will now show up in the app");
        this.$router.push("/");
      } else {
        this.message = "Connecting app to farm failed. Please try again.";
        this.type = "error";
        this.alert = true;
      }
    },

    //******************************** CaptureMode *****************************************

    setupCaptureMode() {
      console.log("setupCaptureMode: called");
      let fileInput = document.getElementById("file-input");
      fileInput.addEventListener("change", (e) => this.runQrCapture(e.target.files));
      this.canvas = this.$refs.canvas.getContext("2d");
    },

    runQrCapture(fileList) {
      let file = null;
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].type.match(/^image\//)) {
          file = fileList[i];
          break;
        }
      }
      if (file !== null) {
        let fileOutput = document.getElementById("file-output");
        fileOutput.src = URL.createObjectURL(file);
        setTimeout(this.decodeQrCapture, 100);
      }
    },

    decodeQrCapture() {
      this.decode(document.getElementById("file-output"));
    },

    //********************************** ScanMode ******************************************

    setupScanMode() {
      console.log("setupScanMode: called");
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        this.previousCode = null;
        this.parity = 0;
        this.active = true;
        this.canvas = this.$refs.canvas.getContext("2d");
        const facingMode = this.useBackCamera ? { exact: "environment" } : "user";
        const handleSuccess = (stream) => {
          if (this.$refs.video.srcObject !== undefined) {
            this.$refs.video.srcObject = stream;
            // eslint-disable-next-line no-undef
          } else if (videoEl.mozSrcObject !== undefined) {
            this.$refs.video.mozSrcObject = stream;
          } else if (window.URL.createObjectURL) {
            this.$refs.video.src = window.URL.createObjectURL(stream);
          } else if (window.webkitURL) {
            this.$refs.video.src = window.webkitURL.createObjectURL(stream);
          } else {
            this.$refs.video.src = stream;
          }
          // iOS play inline
          this.$refs.video.playsInline = true;
          const playPromise = this.$refs.video.play();
          playPromise.catch(() => (this.showPlay = true));
          playPromise.then(this.run);
        };
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode } })
          .then(handleSuccess)
          .catch(() => {
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then(handleSuccess)
              .catch((error) => {
                this.showError(error);
              });
          });
      }
    },

    tick() {
      if (this.$refs.video && this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA) {
        this.decode(this.$refs.video);
      }
      this.run();
    },

    run() {
      if (this.active) {
        requestAnimationFrame(this.tick);
      }
    },

    fullStop() {
      if (this.$refs.video && this.$refs.video.srcObject) {
        this.$refs.video.srcObject.getTracks().forEach((t) => t.stop());
      }
    },

    //*********************************** Shared *******************************************

    decode(source) {
      this.$refs.canvas.width = this.videoWidth;
      this.$refs.canvas.height = this.videoHeight;
      this.canvas.drawImage(source, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      const imageData = this.canvas.getImageData(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      let code = false;
      try {
        code = jsQR(imageData.data, imageData.width, imageData.height);
      } catch (e) {
        // Sometimes JSQR may fail, but we can carry on
        console.error(e);
      }
      if (code) {
        this.drawBox(code.location);
        this.found(code.data);
      }
    },

    found(code) {
      if (this.scanMode === true) {
        if (this.previousCode !== code) {
          this.previousCode = code;
        } else if (this.previousCode === code) {
          this.parity += 1;
        }
        if (this.parity > 2) {
          this.active = this.stopOnScanned ? false : true;
          this.parity = 0;
          this.decoded(code);
        }
      } else {
        this.decoded(code);
      }
    },

    drawLine(begin, end) {
      this.canvas.beginPath();
      this.canvas.moveTo(begin.x, begin.y);
      this.canvas.lineTo(end.x, end.y);
      this.canvas.lineWidth = this.lineWidth;
      this.canvas.strokeStyle = this.lineColor;
      this.canvas.stroke();
    },

    drawBox(l) {
      if (this.drawOnFound) {
        this.drawLine(l.topLeftCorner, l.topRightCorner);
        this.drawLine(l.topRightCorner, l.bottomRightCorner);
        this.drawLine(l.bottomRightCorner, l.bottomLeftCorner);
        this.drawLine(l.bottomLeftCorner, l.topLeftCorner);
      }
    },
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: auto;
}
</style>
