<template>
  <div v-if="showCard">
    <v-card class="mx-2 mb-2" max-width="100%" height="120" elevation="4" :color="reactiveEvent.color" @click="showDialoge">
      <v-card-text>
        <div class="d-flex">
          <div class="icon-container">
            <v-img :src="getIcon" width="80px"></v-img>
            <div v-if="showCow" class="icon-text">
              {{ cow }}
            </div>
          </div>
          <div class="ml-4">
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div>
                  <h3>{{ device }}</h3>
                </div>
                <div class="ml-2">
                  <h3>{{ date }}</h3>
                </div>
              </div>
              <p>{{ message }}</p>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div v-if="showDialog">
      <EventConfirm ref="eventConfirm" :device="device" :date="date" :message="message" :event-id="id" />
    </div>
  </div>
</template>

<script>
import EventConfirm from "@/components/events/EventConfirm.vue";
import EventTranslationService from "@/services/i18n/EventTranslationService.js";
import Icon from "@/services/Icon.js";

export default {
  components: {
    EventConfirm,
  },

  props: {
    event: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showDialog: false,
      id: this.event.id,
      device: this.event.de,
      date: this.event.ev,
      major: this.event.ma,
      minor: this.event.mi,
      cow: this.event.cow,
      message: "...",
      filter: null,
    };
  },

  computed: {
    reactiveEvent() {
      return Object.assign({}, this.event);
    },

    getIcon() {
      return Icon.getIcon(this.major, this.minor);
    },

    showCard() {
      if (this.filter === null || this.filter === this.device) {
        return true;
      } else {
        return false;
      }
    },

    showCow() {
      if (this.cow !== "") {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    $filterChanged(filter) {
      this.filter = filter;
    },
  },

  mounted() {
    EventTranslationService.getTranslation(this.translationRsp, this.event);
  },

  methods: {
    translationRsp(message) {
      this.message = message;
    },

    showDialoge() {
      if (this.reactiveEvent.dialoge === "true") {
        if (this.showDialog === true) {
          this.$refs.eventConfirm.showDialog();
        }
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
  },
};
</script>

<style scoped>
.icon-container {
  position: relative;
}

.icon-text {
  position: absolute;
  top: 34px;
  left: 16px;
}

/* .container {
  border: 1px solid green;
}
.row {
  border: 1px solid red;
}
.col {
  border: 1px solid blue;
} */
</style>

