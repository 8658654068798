import Context from "@/services/Context.js";

const icons = {
  Animal: "list_cow_b",
  Communication: "list_comerror_b",
  Tank: "list_milktank_b",
  Temperature: "list_temp_b",
  Cleaning: "list_cleaning_b",
  MilkFilter: "list_filter_b",
  Vacuum: "list_vakuum2_b",
  None: "list_cow_b",
  MilkingStation: "list_cow_b",
  SmartGate: "list_cow_b",
};

export default {
  getIcon(major, minor) {
    major = Number(major);
    minor = Number(minor);
    let icon = icons.Animal;
    switch (major) {
      case -1:
        icon = icons.None;
        break;
      case 1:
        switch (minor) {
          case 7:
          case 8:
            icon = icons.Cleaning;
            break;
          case 15:
          case 107:
          case 108:
          case 110:
          case 111:
          case 112:
          case 115:
            icon = icons.Animal;
            break;
          case 119:
            icon = icons.Cleaning;
            break;
          case 123:
            icon = icons.Animal;
            break;
          default:
            icon = icons.Communication;
        }
        break;
      case 2:
        icon = icons.MilkingStation;
        break;
      case 3:
        icon = icons.MilkingStation;
        break;
      case 4:
        switch (minor) {
          case 103:
          case 104:
          case 107:
          case 108:
          case 112:
          case 113:
          case 115:
          case 116:
          case 117:
            icon = icons.SmartGate;
            break;
          default:
            icon = icons.Animal;
        }
        break;
      case 5:
        icon = icons.Cleaning;
        switch (minor) {
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 101:
          case 102:
          case 104:
          case 105:
          case 106:
          case 107:
            icon = icons.Tank;
            break;
          case 14:
          case 15:
          case 16:
          case 17:
          case 128:
            icon = icons.MilkFilter;
            break;
          case 108:
          case 109:
          case 110:
          case 112:
          case 113:
          case 114:
          case 115:
          case 122:
          case 126:
          case 127:
          case 131:
          case 132:
          case 207:
            icon = icons.MilkingStation;
            break;
          case 133:
            icon = icons.Cleaning;
            break;
          case 117:
          case 118:
          case 119:
            icon = icons.Temperature;
            break;
          case 121:
            icon = icons.Communication;
            break;
          case 123:
          case 124:
          case 125:
          case 129:
          case 130:
            icon = icons.Animal;
            break;
        }
        break;
      case 6:
        icon = icons.Communication;
        break;
      case 7:
        icon = icons.Cleaning;
        break;
      case 9:
        if (minor < 2) icon = icons.Animal;
        else if (minor < 11) icon = icons.Communication;
        else if (minor < 14) icon = icons.Animal;
        else if (minor == 14) icon = icons.Communication;
        else if (minor == 15) icon = icons.Animal;
        else if (minor == 16) icon = icons.Communication;
        else icon = icons.Animal;
        break;
      case 12:
        icon = icons.Tank;
        break;
      case 13:
        icon = icons.Vacuum;
        break;
      case 14:
        icon = icons.Cleaning; // No alarms but since major exists...
        break;
      case 205:
        icon = icons.Cleaning; // No alarms but since major exists...
        break;
    }
    icon += ".png";
    console.log(Context.tag("getIcon", "icon=" + icon));
    return require("@/assets/" + icon);
  },
};
