import Context from "@/services/Context.js";
import Store from "@/services/Store.js";

const sound = new Audio(require("@/assets/sonar.wav"));
let poller = null;
let guard = null;

function alert() {
  if (isSilentTime() == false) {
    trySound();
    tryVibration();
  }
}

function persistentAlert() {
  alert();
  poller = setInterval(alert, Math.trunc(sound.duration * 1000 + 200));
  guard = setTimeout(tryStopPersistent, 60000); // 10 minutes
}

function tryStopPersistent() {
  clearInterval(poller);
  poller = null;
  clearTimeout(guard);
  guard = null;
}

function isSilentTime() {
  let silent = false;
  if (Store.get(Store.muteMode) == true) {
    silent = true;
  } else {
    let currentTime = new Date().getTime();
    if (Store.get(Store.silentMode) == true && currentTime > getSilentTime(Store.silentFromTime) && currentTime < getSilentTime(Store.silentToTime)) {
      silent = true;
    }
  }
  console.log(Context.tag("isSilentTime", "silent=" + silent));
  return silent;
}

function getSilentTime(key) {
  let silentTime = getSilentDate(key);
  console.log(Context.tag("getSilentTime", "key=" + key + " silentTime=" + silentTime));
  return silentTime.getTime();
}

function getSilentMinutesFromMidnight(key) {
  let silentTime = getSilentTime(key);
  var minutesFromMidnightInUTC = Math.round((silentTime % 86400000) / 60000);
  var hoursFromMidnightInUTC = Math.round(minutesFromMidnightInUTC / 60);
  console.log(Context.tag("getSilentMinutesFromMidnight", "key=" + key + " minutes=" + minutesFromMidnightInUTC + " hours=" + hoursFromMidnightInUTC));
  return minutesFromMidnightInUTC;
}

function getSilentDate(key) {
  let time = Store.get(key).split(":");
  let hour = time[0];
  let minutes = time[1];
  let silentTime = new Date();
  silentTime.setHours(hour);
  silentTime.setMinutes(minutes);
  return silentTime;
}

function setSilentTimeOnServer() {
  let start = -1;
  let end = -1;
  let muteMode = Store.get(Store.muteMode);
  let silentMode = Store.get(Store.silentMode);
  console.log(Context.tagApp("setSilentTimeOnServer", "muteMode=" + muteMode + " silentMode=" + silentMode));
  if (muteMode == true) {
    start = -1;
    end = -2;
  } else if (silentMode == true) {
    start = getSilentMinutesFromMidnight(Store.silentFromTime);
    end = getSilentMinutesFromMidnight(Store.silentToTime);
  }
  console.log(Context.tag("setSilentTimeOnServer", "set_silent_time.php" + Context.backendIdUrl() + "&start=" + start + "&end=" + end));
  Context.backend()
    .get("set_silent_time.php" + Context.backendIdUrl() + "&start=" + start + "&end=" + end)
    .then(
      (result) => {
        console.log(Context.tag("setSilentTimeOnServer", " data=" + JSON.stringify(result.data)));
        if (result.data.rc === "ok") {
          console.log(Context.tagApp("setSilentTimeOnServer", "success: rc=" + result.data.rc));
        } else {
          console.error(Context.tagApp("setSilentTimeOnServer", "error: rc=" + result.data.rc));
        }
      },
      (error) => {
        console.error(Context.tagApp("setSilentTimeOnServer", "error=" + error));
      }
    );
}

function trySound() {
  let volume = Number(Store.get(Store.volumeLevel) / 100);
  if (volume > 0) {
    console.log(Context.tag("trySound", "volume=" + volume + " duration=" + sound.duration));
    sound.volume = volume;
    sound.play();
  }
}

function tryVibration() {
  if (Store.get(Store.vibrateMode) == true) {
    console.log(Context.tag("tryVibration", "vibrate=1000"));
    navigator.vibrate(1000);
  }
}

export default {
  playSound() {
    if (Context.getEnvironment() === Context.environment_android) {
      window.androidNotifierInterface.trySound();
    } else if (Context.getEnvironment() === Context.environment_ios) {
      window.webkit.messageHandlers.trySound.postMessage({});
    } else {
      trySound();
    }
  },

  playVibration() {
    if (Context.getEnvironment() === Context.environment_android) {
      window.androidNotifierInterface.tryVibration();
    } else if (Context.getEnvironment() === Context.environment_ios) {
      window.webkit.messageHandlers.tryVibration.postMessage({});
    } else {
      tryVibration();
    }
  },

  trySetSilentTime() {
    if (Context.getEnvironment() !== Context.environment_browser) {
      setSilentTimeOnServer();
    }
  },

  alertUser(event) {
    if (Context.getEnvironment() === Context.environment_browser) {
      if (Store.get(Store.persistentMode) == true) {
        console.log(Context.tag("alertUser", "persistent notification for device=" + event.de));
        persistentAlert();
      } else {
        console.log(Context.tag("alertUser", "notification for device=" + event.de));
        alert();
      }
      //window.alert(event.de + ": Alarm!");
    }
  },

  stopPersistent() {
    if (Context.getEnvironment() === Context.environment_android) {
      window.androidNotifierInterface.tryStopPersistent();
    } else {
      tryStopPersistent();
    }
  },
};
