<template>
  <div>
    <div v-for="(translation, index) in translations" :key="index">{{ translation }}</div>
  </div>
</template>

<script>
//
// See example in file:
// .../backend_server_backup/langfile_examples/vcalarm.txt
//
// <I18n i18n-key="StartCamera" />
// <I18n i18n-key="StartCamera" i18n-def="Start Camera" />
//

import AppTranslationService from "@/services/i18n/AppTranslationService.js";

export default {
  props: {
    i18nKey: {
      type: String,
      default: "",
    },
    i18nDef: {
      type: String,
      default: "...",
    },
    i18nSuffix: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      translations: [this.i18nDef],
    };
  },

  watch: {
    $languageChanged() {
      AppTranslationService.getTranslation(this.translationRsp, this.i18nKey, this.i18nDef);
    },
  },

  mounted() {
    AppTranslationService.getTranslation(this.translationRsp, this.i18nKey, this.i18nDef);
  },

  methods: {
    translationRsp(translation) {
      translation += this.i18nSuffix;
      if (translation.includes("<br>") === true) {
        this.translations = translation.split("<br>");
      } else {
        this.translations = [translation];
      }
    },
  },
};
</script>
