import Context from "@/services/Context.js";
import Store from "@/services/Store.js";

//
// See example in file:
// .../backend_server_backup/langfile_examples/vcalarm.txt
//

const pending = "_pending_";
const translations = new Map();

function cacheTranslation(key, translation) {
  translations.set(key, translation);
  return translation;
}

function getCachedTranslation(key) {
  if (translations.has(key)) {
    return translations.get(key);
  } else {
    return null;
  }
}

class Translation {
  constructor(rspFnc, key, def) {
    this.rspFnc = rspFnc;
    this.key = key;
    this.def = def;
    this.poller = null;
    this.getTranslation();
  }

  getTranslation() {
    let translation = getCachedTranslation(this.key);
    if (translation === pending) {
      console.log(Context.tag("getTranslation", "pending: key=" + this.key));
      this.poller = setInterval(this.pollPending.bind(this), 300);
    } else if (translation !== null) {
      console.log(Context.tag("getTranslation", "cached: key=" + this.key));
      this.rspFnc(translation);
    } else {
      cacheTranslation(this.key, pending);
      this.backendCall();
    }
  }

  backendCall() {
    let lang = Store.get(Store.langName);
    console.log(Context.tag("backendCall", "restcall: key=" + this.key + " lang=" + lang));
    Context.backend()
      .get("get_app_translation.php?lang=" + lang + "&key=" + this.key)
      .then(
        (result) => {
          console.log(Context.tag("backendCall", "callback: key=" + this.key + " data=" + JSON.stringify(result.data)));
          this.rspFnc(cacheTranslation(this.key, this.parseTranslation(result.data.translation)));
        },
        (error) => {
          console.error(Context.tag("backendCall", "key=" + this.key + " error=" + error));
          setTimeout(this.backendCall.bind(this), 5000);
        }
      );
  }

  pollPending() {
    let translation = getCachedTranslation(this.key);
    if (translation !== pending) {
      console.log(Context.tag("pollPending", "done: key=" + this.key + " translation=" + translation));
      clearInterval(this.poller);
      this.rspFnc(this.parseData(cacheTranslation(this.key, translation)));
    } else {
      //console.log(Context.tag("pollPending", "polled: key" + this.key + " translation=" + translation));
    }
  }

  parseTranslation(translation) {
    if (translation === undefined || translation === "") {
      console.warn(Context.tag("parseTranslation", "translation from backend is MISSING, key=" + this.key));
      return this.def;
    } else {
      let before = translation;
      translation = translation.trim();
      //translation = translation.replace(/<br>/g, ". ");
      if (before !== translation) {
        console.warn(Context.tag("parseTranslation", "translation from backend is modified, key=" + this.key));
      }
      return translation;
    }
  }
}

export default {
  clearCache() {
    translations.clear();
  },

  getTranslation(rspFnc, key, def) {
    new Translation(rspFnc, key, def);
  },
};
