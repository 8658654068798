import axios from "axios";
import Store from "@/services/Store.js";

const version = "web-1.2";

let window = null;
let startTime = new Date();

let environment = undefined;
const environment_android = "android";
const environment_ios = "ios";
const environment_huawei = "huawei";
const environment_browser = "browser";
let test_huawei_mode = false;

const apiClient = axios.create({
  baseURL: "https://hybridnotifier.delaval.one/service/",
  method: "GET",
  //timeout: 5000,
});

const apiClientAlternative = axios.create({
  baseURL: "https://amsnotifier.delaval.com/service/vcalarm/hna/x",
  method: "GET",
  //timeout: 5000,
});

function storeEnvironment() {
  if (loadedFromAndroidApp()) {
    environment = environment_android;
  } else if (loadedFromAppleApp()) {
    environment = environment_ios;
  } else if (test_huawei_mode) {
    environment = environment_huawei;
  } else {
    environment = environment_browser;
  }
  console.log("storeEnvironment: environment=" + environment);
}

function loadedFromAndroidApp() {
  if (window.androidNotifierInterface !== undefined && window.androidNotifierInterface !== null) {
    return true;
  } else {
    //console.log(this.tag("loadedFromAndroid", "vue not loaded from Android WebView"));
    return false;
  }
}

function loadedFromAppleApp() {
  if (window.webkit !== undefined && window.webkit !== null) {
    return true;
  } else {
    //console.log(this.tag("loadedFromApple", "vue not loaded from iOS WebView"));
    return false;
  }
}

function getTimeStamp() {
  let date = new Date();
  return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2) + ":" + ("00" + date.getMilliseconds()).slice(-3);
}

function getTimeDiff() {
  let unit = "s";
  let endTime = new Date();
  let diff = (endTime - startTime) / 1000;
  startTime = endTime;
  if (diff >= 60) {
    diff = diff / 60;
    unit = "m";
  }
  if (diff >= 60) {
    diff = diff / 60;
    unit = "h";
  }
  return Math.round(diff) + unit;
}

export default {
  environment_android,
  environment_ios,
  environment_browser,

  initialize(w) {
    window = w;
    window.appIdentity = this.appIdentity;
    storeEnvironment();
  },

  appIdentity(id, version) {
    console.log("appIdentity: id=" + id + " version=" + version);
    let appVersion = id + "-" + version;
    Store.set(Store.appVersion, appVersion);
    // TODO: Use this to set environment?
  },

  getVersion() {
    return version + " " + Store.get(Store.appVersion);
  },

  getEnvironment() {
    return environment;
  },

  backend() {
    if (Store.get(Store.alternativeBackend) === true) {
      return apiClientAlternative;
    } else {
      return apiClient;
    }
  },

  backendIdUrl() {
    return "?id=" + Store.get(Store.id) + "&seed=" + Store.get(Store.seed);
  },

  stamp() {
    return getTimeStamp();
  },

  tagApp(functionName, msg) {
    Store.logApp(getTimeStamp() + " | " + getTimeDiff() + " | " + functionName + " | " + msg);
    return this.tag(functionName, msg);
  },

  tag(functionName, msg) {
    return "<HNA|" + getTimeStamp() + "|" + functionName + "> " + msg;
  },
};
