<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="time" persistent width="290px">
    <template #activator="{ on, attrs }">
      <v-text-field v-model="time" :label="translation" prepend-icon="mdi-clock-time-four-outline" hide-details readonly v-bind="attrs" text-align="center" :disabled="disabled" v-on="on"></v-text-field>
    </template>
    <v-time-picker v-if="modal" v-model="time" format="24hr" full-width>
      <v-btn text width="134px" elevation="2" color="primary" @click="modal = false">
        <v-icon big center>mdi-close</v-icon>
      </v-btn>
      <v-btn text width="134px" elevation="2" color="primary" @click="clicked(time)">
        <v-icon big center>mdi-check</v-icon>
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
//
// <TimePicker i18n-key="SilentStart" i18n-def="Start time" :store-key="$store.silentFromTime" store-def="00:00" />
//

import Store from "@/services/Store.js";
import AppTranslationService from "@/services/i18n/AppTranslationService.js";

export default {
  props: {
    name: {
      type: String,
      default: "TimePicker",
    },
    i18nKey: {
      type: String,
      default: "",
    },
    i18nDef: {
      type: String,
      default: "...",
    },
    storeKey: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modal: false,
      time: undefined,
      translation: this.i18nDef,
    };
  },

  watch: {
    $languageChanged() {
      if (this.i18nKey.length > 0) {
        AppTranslationService.getTranslation(this.translationRsp, this.i18nKey, this.i18nDef);
      }
    },
  },

  mounted() {
    this.time = Store.get(this.storeKey);
    if (this.i18nKey.length > 0) {
      AppTranslationService.getTranslation(this.translationRsp, this.i18nKey, this.i18nDef);
    }
  },

  methods: {
    clicked(time) {
      console.log("TimePicker clicked: name=" + this.name + " time=" + this.time);
      this.$refs.dialog.save(time);
      Store.set(this.storeKey, time);
      this.$emit("changed", time);
    },

    translationRsp(translation) {
      this.translation = translation;
    },
  },
};
</script>
