import Context from "@/services/Context.js";

let window = null;
let loggerFnc = null;

function logger(msg) {
  if (loggerFnc !== null) {
    loggerFnc(msg);
  }
}

export default {
  initialize(w) {
    window = w;
    window.nativePingCallback = this.nativePingCallback;
  },

  setLogger(fnc) {
    loggerFnc = fnc;
  },

  nativePing() {
    if (Context.getEnvironment() === Context.environment_android) {
      window.androidNotifierInterface.nativePing();
      return "android: Ping";
    } else if (Context.getEnvironment() === Context.environment_ios) {
      window.webkit.messageHandlers.nativePing.postMessage({});
      return "ios: Ping";
    } else {
      return "Vue not loaded from app WebView";
    }
  },

  nativePingCallback(msg) {
    logger("nativePing: " + msg);
  },

  serverPing(rspFnc) {
    Context.backend()
      .get("ping.php")
      .then(
        (result) => {
          //logger(JSON.stringify(result.data));
          rspFnc(result.data.msg);
        },
        (error) => {
          logger(error);
        }
      );
    return "Ping";
  },

  testNotification() {
    if (Context.getEnvironment() === Context.environment_android) {
      window.androidNotifierInterface.testNotification();
    } else if (Context.getEnvironment() === Context.environment_ios) {
      window.webkit.messageHandlers.testNotification.postMessage({});
    } else {
      return "Vue not loaded from app WebView";
    }
  },

  authorize(rspFnc, auth) {
    Context.backend()
      .get("authorize_developer.php?auth=" + auth)
      .then(
        (result) => {
          //logger(JSON.stringify(result.data));
          rspFnc(result.data.rc);
        },
        (error) => {
          logger(error);
        }
      );
  },
};
