<template>
  <v-slider v-model="volume" :prepend-icon="icon" hide-details class="pa-0 ma-0" @click:prepend="silent" @change="changed" @start="fixBug"></v-slider>
</template>

<script>
//
// <VolumeSlider :store-key="$store.volumeLevel" :store-def="100" />
//

import Store from "@/services/Store.js";

export default {
  props: {
    name: {
      type: String,
      default: "VolumeSlider",
    },
    storeKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      volume: 0,
      icon: undefined,
    };
  },

  mounted() {
    this.volume = Store.get(this.storeKey);
    this.selectIcon();
  },

  methods: {
    fixBug(event) {
      console.log("fixBug: Called");
      event.prevent;
    },

    changed() {
      console.log("changed: volume=" + this.volume);
      Store.set(this.storeKey, this.volume);
      this.$emit("changed", this.volume);
      this.selectIcon();
    },

    silent() {
      this.volume = 0;
      this.icon = "mdi-volume-off";
      Store.set(this.storeKey, this.volume);
    },

    selectIcon() {
      if (this.volume > 0) {
        this.icon = "mdi-volume-high";
      } else {
        this.icon = "mdi-volume-off";
      }
    },
  },
};
</script>

