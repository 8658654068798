<template>
  <v-checkbox v-model="checkbox" class="pa-0 ma-0" hide-details :label="translation" :disabled="disabled" @click="clicked"></v-checkbox>
</template>

<script>
//
// <CheckBox i18n-key="Vibrate" i18n-def="Vibrate" :store-key="$store.vibrateMode" :store-def="true" />
//

import Store from "@/services/Store.js";
import AppTranslationService from "@/services/i18n/AppTranslationService.js";

export default {
  props: {
    name: {
      type: String,
      default: "CheckBox",
    },
    i18nKey: {
      type: String,
      default: "",
    },
    i18nDef: {
      type: String,
      default: "...",
    },
    storeKey: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checkbox: true,
      translation: this.i18nDef,
    };
  },

  watch: {
    $languageChanged() {
      if (this.i18nKey.length > 0) {
        AppTranslationService.getTranslation(this.translationRsp, this.i18nKey, this.i18nDef);
      }
    },
  },

  mounted() {
    if (this.storeKey !== null) {
      this.checkbox = Store.get(this.storeKey);
    }
    if (this.i18nKey.length > 0) {
      AppTranslationService.getTranslation(this.translationRsp, this.i18nKey, this.i18nDef);
    }
  },

  methods: {
    clicked() {
      if (this.storeKey !== null) {
        Store.set(this.storeKey, this.checkbox);
        this.$emit("changed", this.checkbox);
      }
    },

    translationRsp(translation) {
      this.translation = translation;
    },
  },
};
</script>

