import Vue from "vue";
import VueRouter from "vue-router";
import Events from "../views/Events.vue";
import Authorize from "../views/Authorize.vue";
import Settings from "../views/Settings.vue";
import About from "../views/About.vue";
import AuthorizeService from "@/services/AuthorizeService.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Events",
    component: Events,
    beforeEnter: (to, from, next) => {
      if (AuthorizeService.isRegistered()) {
        next();
      } else {
        next("/authorize");
      }
    },
  },
  {
    path: "/authorize",
    name: "Authorize",
    component: Authorize,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
