<template>
  <div>
    <v-container v-if="showDeveloper" px-2>
      <v-row no-gutters align="center">
        <v-col>
          <h5>https://amsnotifier.delaval.com/service/test/qr.htm</h5>
          <h5>https://amsnotifier.delaval.com/service/test/qr3.htm</h5>
          <CheckBox i18n-def="Debug mode footer" :store-key="$store.debugMode" />
          <v-btn elevation="2" class="mt-2" width="100%" small @click="clearLog">Clear-Log</v-btn>
          <v-btn elevation="2" class="mt-2" width="100%" small @click="printHello">Print-Hello</v-btn>
          <v-btn elevation="2" class="mt-2" width="100%" small @click="nativePing">Native-Ping</v-btn>
          <v-btn elevation="2" class="mt-2" width="100%" small @click="serverPing">Server-Ping</v-btn>
          <v-btn elevation="2" class="mt-2" width="100%" small @click="testNotification">Test-Notification</v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters align="center">
        <v-col cols="4" class="pr-2">
          <v-btn elevation="2" class="mt-2" width="100%" small @click="listStore">list-Store</v-btn>
        </v-col>
        <v-col cols="4" class="pr-2">
          <v-btn elevation="2" class="mt-2" width="100%" small @click="clearStore">clear-Store</v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn elevation="2" class="mt-2" width="100%" small @click="setId">set-id</v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters align="center">
        <v-col>
          <h5 v-for="(output, index) in outputs" :key="index" style="overflow-wrap: break-word; word-wrap: break-word">{{ output }}</h5>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else pa-4>
      <I18n i18n-key="About" i18n-def="This app shows and handles alarms from the equipment on your DeLaval farm." />
      <v-divider class="my-4"></v-divider>
      <I18n i18n-key="Date" i18n-def="Date" i18n-suffix=": 2021-04-07" />
      <I18n i18n-key="Version" i18n-def="Version" :i18n-suffix="': ' + getVersion()" />
      <input class="my-4" type="password" />
    </v-container>
  </div>
</template>

<script>
import Context from "@/services/Context.js";
import CheckBox from "@/components/CheckBox.vue";
import TestService from "@/services/TestService.js";
import Store from "@/services/Store.js";

export default {
  components: {
    CheckBox,
  },

  data() {
    return {
      outputs: [],
      showDeveloperAuth: "",
      showDeveloper: false,
    };
  },

  created() {
    window.addEventListener("keypress", this.atKeypress);
    TestService.setLogger(this.logger);
  },

  destroyed() {
    window.removeEventListener("keypress", this.atKeypress);
  },

  methods: {
    reload() {
      window.location.reload();
    },

    getVersion() {
      return Context.getVersion();
    },

    atKeypress(e) {
      if (e.keyCode === 13) {
        if (this.showDeveloperAuth.length > 0) {
          this.logger("atKeypress: try authorize");
          TestService.authorize(this.showDeveloperRsp, this.showDeveloperAuth);
        }
        this.showDeveloperAuth = "";
      } else {
        this.showDeveloperAuth += String.fromCharCode(e.keyCode);
        if (this.showDeveloperAuth.length > 10) {
          this.showDeveloperAuth = "";
        }
      }
    },

    showDeveloperRsp(rc) {
      this.logger("showDeveloperRsp: rc=" + rc);
      if (rc === "ok") {
        this.showDeveloper = true;
      }
    },

    clearLog() {
      this.outputs = [];
    },

    printHello() {
      this.logger("printHello: Hello!");
    },

    nativePing() {
      this.logger("nativePing: " + TestService.nativePing());
    },

    serverPing() {
      this.logger("serverPing: " + TestService.serverPing(this.serverPingRsp));
    },

    serverPingRsp(msg) {
      this.logger("serverPing: " + msg);
    },

    testNotification() {
      this.logger("testNotification: Called");
      TestService.testNotification();
    },

    listStore() {
      this.logger("listStore: " + Store.list());
    },

    clearStore() {
      this.logger("clearStore: Done");
      Store.clearAll();
    },

    setId() {
      let id = "81699";
      let seed = "1Yp5873Tb1CA78t6Ktr1my7gZ3s32ZTt";
      this.logger("setId: id=" + id + " seed=" + seed);
      Store.set(Store.id, id);
      Store.set(Store.seed, seed);
    },

    logger(msg) {
      let stamp = Context.stamp();
      console.log("<HNA|" + stamp + "|TestService.js|developer> " + msg);
      this.outputs.push(stamp + " " + msg);
    },
  },
};
</script>
