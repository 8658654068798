import Context from "@/services/Context.js";
import Store from "@/services/Store.js";

//
// See example in file:
// .../backend_server_backup/langfile_examples/MessagesVMS_sv.xml
//

const pending = "_pending_";
const translations = new Map();

function cacheTranslation(key, translation) {
  translations.set(key, translation);
  return translation;
}

function getCachedTranslation(key) {
  if (translations.has(key)) {
    return translations.get(key);
  } else {
    return null;
  }
}

class Translation {
  constructor(rspFnc, event) {
    this.rspFnc = rspFnc;
    this.event = event;
    this.key = null;
    this.poller = null;
    this.getTranslation();
  }

  getTranslation() {
    this.key = this.event.ma + "_" + this.event.mi;
    let translation = getCachedTranslation(this.key);
    if (translation === pending) {
      console.log(Context.tag("getTranslation", "pending: key=" + this.key));
      this.poller = setInterval(this.pollPending.bind(this), 300);
    } else if (translation !== null) {
      console.log(Context.tag("getTranslation", "cached: key=" + this.key));
      this.rspFnc(this.parseData(translation), this.event.de);
    } else {
      cacheTranslation(this.key, pending);
      this.backendCall();
    }
  }

  backendCall() {
    let lang = Store.get(Store.langFile);
    console.log(Context.tag("backendCall", "restcall: key=" + this.key + " lang=" + lang));
    Context.backend()
      .get("get_event_translation.php?lang=" + lang + "&major=" + this.event.ma + "&minor=" + this.event.mi)
      .then(
        (result) => {
          console.log(Context.tag("backendCall", "callback: key=" + this.key + " data=" + JSON.stringify(result.data)));
          this.rspFnc(this.parseData(cacheTranslation(this.key, result.data.translation)), this.event.de);
        },
        (error) => {
          console.error(Context.tag("backendCall", "error=" + error));
          setTimeout(this.backendCall.bind(this), 5000);
        }
      );
  }

  pollPending() {
    let translation = getCachedTranslation(this.key);
    if (translation !== pending) {
      console.log(Context.tag("pollPending", "done: key=" + this.key + " translation=" + translation));
      clearInterval(this.poller);
      this.rspFnc(this.parseData(cacheTranslation(this.key, translation)), this.event.de);
    } else {
      //console.log(Context.tag("pollPending", "polled: key" + this.key + " translation=" + translation));
    }
  }

  parseData(message) {
    message = message.replace("{SupportingData1}", this.event.u1);
    message = message.replace("{SupportingData2}", this.event.u2);
    message = message.replace("{SupportingData3}", this.event.u3);
    message = message.replace("{SupportingData4}", this.event.u4);
    message = message.replace("{SupportingData5}", this.event.u5);
    message = message.replace("{SupportingData6}", this.event.u6);
    message = message.replace("{sourcename}", this.event.u1);
    message = message.replace("{devicename}", this.event.de);
    return message;
  }
}

export default {
  clearCache() {
    translations.clear();
  },

  getTranslation(rspFnc, event) {
    new Translation(rspFnc, event);
  },
};
