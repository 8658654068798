<template>
  <div>
    <div v-if="showError" class="events-text">
      <v-container>
        <v-alert class="ma-0" dense type="error">
          <I18n i18n-key="CantGetAlarms" i18n-def="Can't get alarms right now :-(" />
        </v-alert>
      </v-container>
    </div>
    <div v-else class="events-container pt-2" @click="stopPersistentAlert">
      <div class="events-text">
        <v-container>
          <v-alert class="ma-0" dense type="info">
            <I18n i18n-key="NoAlarmsToShow" i18n-def="No alarms to show ;-)" />
          </v-alert>
        </v-container>
      </div>
      <EventCard v-for="event in events" :key="event.id" :event="event" />
    </div>
  </div>
</template>

<script>
import Alarm from "@/services/Alarm.js";
import EventCard from "@/components/events/EventCard.vue";
import EventService from "@/services/EventService.js";

export default {
  components: {
    EventCard,
  },

  data() {
    return {
      events: [],
      showError: false,
    };
  },

  watch: {
    $errorChanged(value) {
      this.showError = value;
    },
  },

  mounted() {
    this.showError = this.$errorChanged;
    EventService.getAlarms(this.getAlarmsRsp);
  },

  methods: {
    getAlarmsRsp(events) {
      console.log("getAlarmsRsp: hasLength=" + this.events.length + " gotLength=" + events.length);
      this.events = events;
    },

    stopPersistentAlert() {
      Alarm.stopPersistent();
    },
  },
};
</script>

<style scoped>
.events-container {
  position: relative;
}

.events-text {
  position: absolute;
  width: 100%;
  top: 4px;
}
</style>