import Context from "@/services/Context.js";
import Store from "@/services/Store.js";

let window = null;
let successRsp = null;
let lastQrCode = undefined;

function isAuthorized() {
  let id = Store.get(Store.id);
  let seed = Store.get(Store.seed);
  if (id === "-1" || seed === "-1") {
    return false;
  } else {
    return true;
  }
}

function tryAuthorize(qr) {
  console.log(Context.tag("tryAuthorize", "called: qr=" + qr));
  if (qr === undefined) {
    if (Context.getEnvironment() === Context.environment_android) {
      window.androidNotifierInterface.scanQr();
    } else if (Context.getEnvironment() === Context.environment_ios) {
      window.webkit.messageHandlers.nativePing.scanQr({});
    } else {
      return false;
    }
  } else {
    authorize(qr);
  }
  return true;
}

function authorize(qr) {
  lastQrCode = qr;
  if (Context.getEnvironment() === Context.environment_android) {
    window.androidNotifierInterface.getToken();
  } else if (Context.getEnvironment() === Context.environment_ios) {
    window.webkit.messageHandlers.getToken.postMessage({});
  } else {
    console.warn(Context.tagApp("getToken", "can only use token in app"));
    gotToken(getDummyToken());
  }
}

function getDummyToken() {
  let token = Store.get(Store.token);
  if (token === "-1") {
    token = "dummy_" + Math.random().toString(36).replace("0.", "");
    Store.set(Store.token, token);
  }

  console.log(Context.tagApp("getDummyToken", "token=" + token));
  return token;
}

function gotToken(token) {
  let device = 0;
  if (Context.getEnvironment() === Context.environment_android) {
    device = 2;
  } else if (Context.getEnvironment() === Context.environment_ios) {
    device = 4;
  }
  console.log(Context.tagApp("authorize", " qr=" + lastQrCode + " token=" + token + " device=" + device));
  Context.backend()
    .get("validate_authorization_code.php?qr=" + lastQrCode + "&token=" + token + "&device=" + device)
    .then(
      (result) => {
        console.log(Context.tag("authorize", " data=" + JSON.stringify(result.data)));
        let id = result.data.id;
        let seed = result.data.seed;
        if (id === "-1" || seed === "-1") {
          console.error(Context.tagApp("authorize", "error: id=" + id + " seed=" + seed));
          successRsp(false);
        } else {
          Store.set(Store.id, id);
          Store.set(Store.seed, seed);
          if (token.startsWith("dummy") === true) {
            successRsp(true);
          } else {
            registerToken(token);
          }
        }
      },
      (error) => {
        console.error(Context.tagApp("authorize", "error=" + error));
        successRsp(false);
      }
    );
}

function registerToken(token) {
  Context.backend()
    .get("register_token.php" + Context.backendIdUrl() + "&token=" + token)
    .then(
      (result) => {
        console.log(Context.tag("registerToken", " data=" + JSON.stringify(result.data)));
        if (result.data.rc === "ok") {
          console.log(Context.tagApp("registerToken", "success: rc=" + result.data.rc));
          successRsp(true);
        } else {
          console.error(Context.tagApp("registerToken", "error: rc=" + result.data.rc));
          successRsp(false);
        }
      },
      (error) => {
        console.error(Context.tagApp("registerToken", "error=" + error));
        successRsp(false);
      }
    );
}

function updateToken(token) {
  Context.backend()
    .get("update_token.php" + Context.backendIdUrl() + "&token=" + token)
    .then(
      (result) => {
        console.log(Context.tag("updateToken", " data=" + JSON.stringify(result.data)));
        if (result.data.rc === "fail") {
          console.error(Context.tagApp("updateToken", "error: rc=" + result.data.rc));
        } else {
          console.log(Context.tagApp("updateToken", "success: rc=" + result.data.rc));
        }
      },
      (error) => {
        console.error(Context.tagApp("updateToken", "error=" + error));
      }
    );
}

export default {
  initialize(w) {
    window = w;
    window.tokenChanged = this.tokenChanged;
    window.tokenResult = this.tokenResult;
  },

  isRegistered() {
    return isAuthorized();
  },

  register(successRspFnc, qr) {
    successRsp = successRspFnc;
    return tryAuthorize(qr);
  },

  mayCallCameraPermissionDialogue() {
    if (Context.getEnvironment() === Context.environment_android) {
      console.log(Context.tag("callCameraPermissionDialogue", " called"));
      window.androidNotifierInterface.callCameraPermissionDialogue();
    }
  },

  tokenChanged(token) {
    console.log(Context.tagApp("tokenChanged", "token=" + token));
    updateToken(token);
  },

  tokenResult(token) {
    console.log(Context.tagApp("tokenResult", "token=" + token));
    gotToken(token);
  },
};
