import Context from "@/services/Context.js";

//
// See example in file:
// .../backend_server_backup/langfile_examples/languages.txt
//

let languages = null;
let languagesRspFnc = null;

function getLangs() {
  if (languages !== null) {
    console.log(Context.tag("getLangs", "load from cache"));
    languagesRspFnc(languages);
  } else {
    backendCall();
  }
}

function backendCall() {
  console.log(Context.tag("backendCall", "load from cache"));
  Context.backend()
    .get("get_languages.php")
    .then(
      (result) => {
        languages = result.data;
        console.log(Context.tag("backendCall", "data=" + JSON.stringify(languages)));
        languagesRspFnc(languages);
      },
      (error) => {
        console.error(Context.tag("backendCall", "error=" + error));
        setTimeout(this.backendCall.bind(this), 5000);
      }
    );
}

export default {
  getLanguages(rspFnc) {
    languagesRspFnc = rspFnc;
    getLangs();
  },
};
