<template>
  <div>
    <v-app>
      <v-app-bar app color="primary" dark>
        <v-toolbar-title>
          <v-btn to="/about" text small>
            <v-img src="@/assets/delaval_white.png" />
          </v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <FilterList />
          <v-btn to="/" text>
            <v-icon small center>mdi-bell</v-icon>
          </v-btn>
          <v-btn to="/settings" text>
            <v-icon small center>mdi-cog</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-main class="mb-20">
        <router-view />
      </v-main>
      <div v-if="showDebug" class="mt-20">
        <v-footer height="171px" color="primary" dark fixed>
          <DebugLog />
        </v-footer>
      </div>
      <div v-else class="mt-6">
        <v-footer height="24px" color="primary" dark fixed>
          <PollBar />
        </v-footer>
      </div>
    </v-app>
  </div>
</template>

<script>
import FilterList from "@/components/FilterList.vue";
import DebugLog from "@/components/DebugLog.vue";
import PollBar from "@/components/PollBar.vue";
import Store from "@/services/Store.js";

export default {
  components: {
    FilterList,
    DebugLog,
    PollBar,
  },

  data() {
    return {
      showDebug: false,
    };
  },

  watch: {
    $debugChanged(value) {
      this.showDebug = value;
    },
  },

  mounted() {
    this.showDebug = Store.get(Store.debugMode);
  },
};
</script>

