<template>
  <v-container class="pa-0 ma-0">
    <v-col class="pa-0 ma-0">
      <v-list class="pa-0 ma-0 overflow-y-auto" max-height="140" min-height="140" dense color="primary">
        <v-list-item-title v-for="(log, index) in logs" :key="index" class="wrap-text">
          <h6>{{ log }}</h6>
        </v-list-item-title>
      </v-list>
      <v-switch v-model="pause" label="pause" class="pa-0 ma-0" dense></v-switch>
    </v-col>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      logs: [],
      pause: false,
    };
  },

  watch: {
    $logChanged(logs) {
      if (this.pause === false) {
        this.logs = logs;
      }
    },
  },
};
</script>

<style scoped>
.wrap-text {
  white-space: normal;
}
</style>

