<template>
  <div>
    <v-container class="ma-0 pa-0 px-4">
      <v-row>
        <v-col>
          <p class="ma-0"><I18n i18n-key="SilentSettings" i18n-def="Silent time settings" /></p>
          <v-divider></v-divider>
          <v-col>
            <v-row>
              <v-col cols="6" class="ma-0 pa-0">
                <TimePicker i18n-key="SilentStart" i18n-def="Start time" :store-key="$store.silentFromTime" :disabled="silentDisabled" @changed="trySetSilentTime" />
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <TimePicker i18n-key="SilentEnd" i18n-def="End time" :store-key="$store.silentToTime" :disabled="silentDisabled" @changed="trySetSilentTime" />
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <CheckBox i18n-key="SilentActive" i18n-def="Active silent time" :store-key="$store.silentMode" :disabled="silentDisabled" @changed="trySetSilentTime" />
            </v-row>
            <v-row class="mt-2">
              <CheckBox i18n-key="MuteActive" i18n-def="Don't disturb" :store-key="$store.muteMode" @changed="trySetSilentTime" />
            </v-row>
          </v-col>
        </v-col>
      </v-row>

      <v-row v-if="!environment_ios">
        <v-col>
          <p class="ma-0"><I18n i18n-key="AlertSettings" i18n-def="Alert settings" /></p>
          <v-divider></v-divider>
          <v-col>
            <v-row>
              <CheckBox i18n-key="AlertVibrate" i18n-def="Vibrate" :store-key="$store.vibrateMode" @changed="tryVibrate" />
            </v-row>
            <v-row class="mt-2">
              <CheckBox i18n-key="AlertPersistent" i18n-def="Persistent" :store-key="$store.persistentMode" />
            </v-row>
            <v-row>
              <VolumeSlider :store-key="$store.volumeLevel" @changed="trySound" />
            </v-row>
          </v-col>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="ma-0"><I18n i18n-key="AppSettings" i18n-def="App settings" /></p>
          <v-divider></v-divider>
          <v-col>
            <v-row>
              <LangList :store-key-name="$store.langName" :store-key-file="$store.langFile" />
            </v-row>
            <v-row class="mt-2">
              <CheckBox i18n-key="AppShowConfirmed" i18n-def="Show confirmed" :store-key="$store.confirmedMode" />
            </v-row>
            <v-row class="mt-8">
              <v-btn elevation="2" color="red" block small :href="url"><I18n i18n-key="Reload" i18n-def="Reload app" /></v-btn>
              <v-btn class="mt-4" elevation="2" color="red" block small @click="reconnectToFarm"><I18n i18n-key="ReconnectToFarm" i18n-def="Reconnect to farm" /></v-btn>
              <v-btn class="mt-4" elevation="2" color="red" block small @click="resetAll"><I18n i18n-key="ResetAll" i18n-def="Reset all" /></v-btn>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Context from "@/services/Context.js";
import Store from "@/services/Store.js";
import Alarm from "@/services/Alarm.js";
import EventService from "@/services/EventService.js";
import LangList from "@/components/LangList.vue";
import TimePicker from "@/components/TimePicker.vue";
import CheckBox from "@/components/CheckBox.vue";
import VolumeSlider from "@/components/VolumeSlider.vue";

export default {
  components: {
    LangList,
    TimePicker,
    CheckBox,
    VolumeSlider,
  },

  data() {
    return {
      silentDisabled: false,
    };
  },

  computed: {
    environment_ios() {
      return Context.getEnvironment() === Context.environment_ios;
    },

    url() {
      return window.location.origin;
    },
  },

  mounted() {
    this.silentDisabled = Store.get(Store.muteMode);
  },

  methods: {
    trySetSilentTime(value) {
      console.log("trySetSilentTime: changed value=" + value);
      this.silentDisabled = Store.get(Store.muteMode);
      Alarm.trySetSilentTime();
    },

    tryVibrate(value) {
      console.log("tryVibrate: changed value=" + value);
      Alarm.playVibration();
    },

    trySound(value) {
      console.log("trySound: changed value=" + value);
      Alarm.playSound();
    },

    reconnectToFarm() {
      console.log("reconnectToFarm: clicked");
      this.$router.push("/authorize");
    },

    resetAll() {
      console.warn("resetAll: clicked");
      EventService.stopPoll();
      Store.clearAll();
      this.$router.push("/authorize");
    },
  },
};
</script>

<style scoped>
/* .note {
  font-size: 14px;
} */

/* .container {
  border: 1px solid green;
}
.row {
  border: 1px solid red;
}
.col {
  border: 1px solid blue;
} */
</style>




