<template>
  <div>
    <v-container>
      <p class="ma-0"><I18n i18n-key="ConnectFarmInstruction" i18n-def="Connect the app to your farm" /></p>
      <v-alert class="ma-0 my-2" dense type="info">
        <I18n i18n-key="ReadQrInstruction" i18n-def="Go to any MS touch screen:<br>VMS menu -> System -> Farm Code<br>Aim the camera at the QR-code." />
      </v-alert>
      <v-alert class="ma-0" dense type="info">
        <I18n i18n-key="CameraAccessInstruction" i18n-def="You have to grant camera access permisson in the pop-up dialoge." />
      </v-alert>
      <QrReader :scan-mode="scanMode" />
    </v-container>
  </div>
</template>

<script>
import QrReader from "@/components/QrReader.vue";
import Context from "@/services/Context.js";

export default {
  components: {
    QrReader,
  },

  computed: {
    scanMode() {
      if (Context.getEnvironment() === Context.environment_ios) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>